import {
  Add,
  BarChart,
  CalendarMonth,
  Checklist,
  Clear,
  Dashboard,
  DeleteForever,
  Edit,
  ExpandLess,
  ExpandMore,
  FilterAlt,
  FilterAltOutlined,
  FirstPage,
  Home,
  Inbox,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Logout,
  Mail,
  MoreVert,
  Save,
  Settings,
  SsidChart,
  StarBorder,
  ViewList,
} from "@mui/icons-material";
import { ReactElement } from "react";

interface iconListInterface {
  [key: string]: ReactElement;
}

const iconList: iconListInterface = {
  // UI
  ExpandLess: <ExpandLess />,
  ExpandMore: <ExpandMore />,
  Logout: <Logout />,
  Add: <Add />,
  Edit: <Edit />,
  Save: <Save />,
  // Mail
  Inbox: <Inbox />,
  Mail: <Mail />,
  // Dashboard
  Dashboard: <Dashboard />,
  Settings: <Settings />,
  MoreVert: <MoreVert />,
  Clear: <Clear />,
  ViewList: <ViewList />,
  DeleteForever: <DeleteForever />,
  FilterAlt: <FilterAlt />,
  FilterAltOutlined: <FilterAltOutlined />,
  Checklist: <Checklist />,
  CalendarMonth: <CalendarMonth />,
  // Pager
  FirstPage: <FirstPage />,
  LastPage: <LastPage />,
  KeyboardArrowLeft: <KeyboardArrowLeft />,
  KeyboardArrowRight: <KeyboardArrowRight />,
  // Chart
  BarChart: <BarChart />,
  SsidChart: <SsidChart />,
  // Misc
  Home: <Home />,
  StarBorder: <StarBorder />,
};

const Icons = ({ iconName }: { iconName: string }) => {
  return iconList[iconName];
};

export default Icons;
