import { List, ListItem, SxProps } from "@mui/material";
import Icons from "components/common/Icons";
import { useLeftNavIsEditMode, useLeftNavItems, useSetLeftNavIsEditMode } from "globalStates/leftNavState";
import { useUser } from "models/UserModel";
import { useCallback } from "react";

const sxListItem: SxProps = {
  justifyContent: "center",
  borderWidth: "0 1px 0 0",
  borderStyle: "solid",
  borderColor: "grey.300",
  color: "grey.400",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "grey.200",
  },
  "&.active": {
    backgroundColor: "grey.200",
    color: "grey.600",
  },
  "&:last-child": {
    borderWidth: 0,
  },
};

const LeftNavBottom = () => {
  const setEditMode = useSetLeftNavIsEditMode();
  const isEditMode = useLeftNavIsEditMode();
  const { allItems } = useLeftNavItems();
  const { patchUser } = useUser();

  const save = useCallback(async () => {
    await patchUser({ routes: allItems });
  }, [allItems, patchUser]);

  const handleOnAdd = useCallback(() => {
    // /////////////
  }, []);

  const handleOnEdit = useCallback(() => {
    isEditMode && save();
    setEditMode((prev) => !prev);
  }, [isEditMode, save, setEditMode]);

  return (
    <List
      sx={{
        padding: 0,
        backgroundColor: "grey.100",
        display: "flex",
      }}
    >
      <ListItem sx={sxListItem} onClick={handleOnAdd}>
        <Icons iconName="Add" />
      </ListItem>
      <ListItem sx={sxListItem} className={isEditMode ? "active" : undefined} onClick={handleOnEdit}>
        {isEditMode ? <Icons iconName="Save" /> : <Icons iconName="Edit" />}
      </ListItem>
    </List>
  );
};

export default LeftNavBottom;
