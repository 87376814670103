import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { arrayMove } from "@dnd-kit/sortable";
import { allRoutesSelector, TRoute, useRouteStates } from "models/RouteModel";

const leftNavOpenState = atom<boolean>({
  key: "leftNavOpen",
  default: true,
});

export const useLeftNavOpen = () => {
  const setState = useSetRecoilState(leftNavOpenState);

  const isOpen = useRecoilValue(leftNavOpenState);
  const setOpen = () => setState(true);
  const setClose = () => setState(false);
  const setToggle = () => setState(!isOpen);

  return { isOpen, setOpen, setClose, setToggle };
};

type TLeftNavItem = TRoute;

const leftNavItemState = atomFamily<TLeftNavItem, number>({
  key: "leftNavItemState",
  default: undefined,
});

const leftNavItemIdsState = atom<number[]>({
  key: "leftNavItemIdsState",
  default: selector({
    key: "leftNavItemIdsStateDefault",
    get: ({ get }) => {
      const result: number[] = [];
      get(allRoutesSelector)
        .filter((route) => !route.depth)
        .forEach((route) => result.push(route.id));
      return result;
    },
  }),
});

const leftNavAllItemIdsState = atom<number[]>({
  key: "leftNavAllItemIdsState",
  default: selector({
    key: "leftNavAllItemIdsStateDefault",
    get: ({ get }) => {
      return get(allRoutesSelector).map((route) => route.id);
    },
  }),
});

const leftNavAllItemIdsSelector = selector<number[]>({
  key: "leftNavAllItemIdsSelector",
  get: ({ get }) => get(leftNavAllItemIdsState),
  set: ({ set }, ids) => set(leftNavAllItemIdsState, ids),
});

const leftNavItemSelector = selectorFamily<TLeftNavItem, number>({
  key: "leftNavItemSelector",
  get:
    (id) =>
    ({ get }) => {
      const item = get(leftNavItemState(id));
      if (item) return item;
      const routes = get(allRoutesSelector);
      const newItem = [...routes].find((item) => item.id === id);
      return newItem ? newItem : ({} as TLeftNavItem);
    },
  set:
    (id) =>
    ({ set }, item) => {
      set(leftNavItemState(id), item);
    },
});

const leftNavItemsSelector = selector<TLeftNavItem[]>({
  key: "allLeftNavItemsSelector",
  get: ({ get }) => {
    const ids = get(leftNavItemIdsState);
    return ids.map((id) => get(leftNavItemSelector(id)));
  },
  set: ({ set }, items) => {
    if (items instanceof DefaultValue) return;
    items.forEach((item) => set(leftNavItemState(item.id), item));
  },
});

const useLeftNavItem = () => {
  const items = useRecoilValue(leftNavItemsSelector);

  const setItem = useRecoilCallback(({ set }) => (item: TLeftNavItem) => {
    set(leftNavItemSelector(item.id), item);
  });

  const resetItem = useRecoilCallback(({ reset }) => (item: TLeftNavItem) => {
    reset(leftNavItemSelector(item.id));
  });

  return { items, setItem, resetItem };
};

const useLeftNavItemIds = () => {
  const ids = useRecoilValue(leftNavItemIdsState);

  const setIds = useRecoilCallback(({ set }) => (ids: Array<TLeftNavItem["id"]>) => {
    set(leftNavItemIdsState, ids);
  });

  return { ids, setIds };
};

const idsArrayMove = (ids: any[], activeId: number, overId: number) => {
  const oldIndex = ids.findIndex((id) => id === activeId);
  const newIndex = ids.findIndex((id) => id === overId);
  const result = arrayMove(ids, oldIndex, newIndex);
  return result;
};

export const useLeftNavItems = () => {
  const { items, setItem, resetItem } = useLeftNavItem();
  const userItems = items.filter((i) => i.id !== 1);
  // [ToDo] 将来的にはAPIから取得予定
  const fixedItems: TRoute[] = [
    {
      id: 1,
      icon: "Home",
      page: "Home",
      title: "Home",
      type: "route",
      url: "/",
    },
  ];
  const { ids: itemIds, setIds: setItemIds } = useLeftNavItemIds();
  const { isOpen, setOpen, setClose } = useLeftNavOpenFolders();
  const { allRoutes: routes, setRoute } = useRouteStates();
  const setOpenFolders = useSetRecoilState(leftNavOpenFoldersState);
  const [allItemIds, setAllItemIds] = useRecoilState(leftNavAllItemIdsSelector);

  const allItems = allItemIds.map((id) => routes.find((route) => route.id === id));

  const setItemsOnDragEnd = (activeId: number, overId: number) => {
    const activeItem = userItems.find((item) => item.id === activeId) as TRoute;
    setRoute(activeItem);
    resetItem(activeItem);
    setAllItemIds(idsArrayMove(allItemIds, activeId, overId));
    setItemIds(idsArrayMove(itemIds, activeId, overId));
  };

  const setItemsOnCollapse = (folderId: number, toggletype?: "open" | "close") => {
    const children = routes.filter((item) => item.parentId === folderId);
    let newItems = [];
    if (isOpen(folderId) || toggletype === "close") {
      setClose(folderId);
      newItems = userItems.filter((item) => routes.find((route) => route.id === item.id)?.parentId !== folderId);
    } else {
      setOpen(folderId);
      const i = userItems.findIndex((item) => item.id === folderId) + 1;
      newItems = [...userItems.slice(0, i), ...children, ...userItems.slice(i)];
    }
    setItemIds(newItems.map((item) => item.id));
  };

  const closeAllFolder = () => {
    const folders = routes.filter((item) => item.type === "folder");
    const folderIds = folders.map((item) => item.id);
    setOpenFolders([]);
    const newItemIds = routes.filter((item) => !folderIds.includes(item.parentId as number)).map((item) => item.id);
    setItemIds(newItemIds);
  };

  return {
    items,
    allItems,
    userItems,
    fixedItems,
    setItem,
    setItemsOnDragEnd,
    setItemsOnCollapse,
    closeAllFolder,
  };
};

const leftNavOpenFoldersState = atom<number[]>({
  key: "leftNavOpenFoldersState",
  default: [],
});

export const useLeftNavOpenFolders = () => {
  const setState = useSetRecoilState(leftNavOpenFoldersState);
  const openIds = useRecoilValue(leftNavOpenFoldersState);
  // const { setItemsOnCollapse } = useLeftNavItems();
  const setOpen = (openId: number) => {
    const ids = [...openIds];
    ids.push(openId);
    setState(ids);
    // setItemsOnCollapse(openId);
  };
  const setClose = (closeId: number) => {
    setState(openIds.filter((id) => id !== closeId));
    // setItemsOnCollapse(closeId);
  };
  const isOpen = (id: number) => openIds.includes(id);

  return { openIds, setOpen, setClose, isOpen };
};

const isEditMode = atom<boolean>({
  key: "leftNav/isEditMode",
  default: false,
});

export const useLeftNavIsEditMode = () => useRecoilValue(isEditMode);

export const useSetLeftNavIsEditMode = () => useSetRecoilState(isEditMode);
