import React, { useCallback, useState } from "react";
import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { useLeftNavItems, useLeftNavOpenFolders } from "globalStates/leftNavState";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { TRoute, useRouteStates } from "models/RouteModel";
import DndLeftNavItemSortable from "./DndLeftNavItemSortable";
import DndLeftNavItem from "./DndLeftNavItem";
import Icons from "components/common/Icons";
import { useNavigate } from "react-router-dom";

const LeftNavMenu = () => {
  const { fixedItems, userItems, setItem, setItemsOnDragEnd, closeAllFolder } = useLeftNavItems();
  const [activeId, setActiveId] = useState<number | null>(null);
  const activeItem = userItems.find((item) => item.id === activeId);
  const { isOpen: isOpenParent } = useLeftNavOpenFolders();

  const navigate = useNavigate();
  const { setCurrentRoute } = useRouteStates();

  const handleOnDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      setActiveId(active.id as number);
      if (userItems.find((route) => route.id === active.id)?.type === "folder") {
        closeAllFolder();
      }
    },
    [closeAllFolder, userItems],
  );

  const handleOnDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveId(null);
      const { active, over } = event;
      active && over && active.id !== over.id && setItemsOnDragEnd(active.id as number, over.id as number);
    },
    [setItemsOnDragEnd],
  );

  const handleOnDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      if (active && over) {
        const activeItem = { ...userItems.find((item) => item.id === active.id) } as TRoute;
        const overItem = userItems.find((item) => item.id === over.id) as TRoute;
        if (activeItem && overItem) {
          if (
            overItem?.depth ||
            (overItem?.type === "folder" &&
              isOpenParent(overItem.id) &&
              active.data.current?.sortable.index < over.data.current?.sortable.index)
          ) {
            activeItem["depth"] = 1;
            activeItem["parentId"] = overItem.type === "folder" ? overItem.id : overItem.parentId;
          } else {
            activeItem["depth"] = 0;
            activeItem["parentId"] = undefined;
          }
          setItem(activeItem);
        }
      }
    },
    [isOpenParent, setItem, userItems],
  );

  return (
    <>
      <List component="nav">
        <ListSubheader component="div" id="menu_kotei">
          固定メニュー
        </ListSubheader>
        {fixedItems.map((item) => (
          <ListItemButton
            key={item.id}
            dense
            onClick={() => {
              item.url && navigate(item.url);
              setCurrentRoute(item);
            }}
          >
            {item.icon && (
              <ListItemIcon>
                <Icons iconName={item.icon} />
              </ListItemIcon>
            )}
            <ListItemText>{item.title}</ListItemText>
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader component="div" id="menu_free">
          自由メニュー
        </ListSubheader>
        <div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragStart={handleOnDragStart}
            onDragEnd={handleOnDragEnd}
            onDragOver={handleOnDragOver}
          >
            <SortableContext items={userItems}>
              {userItems.map((item) => {
                const id = `${item.type}_${item.id}`;
                switch (item.type) {
                  case "folder":
                  case "route":
                    return <DndLeftNavItemSortable key={id} item={item as TRoute} />;
                  default:
                    return false;
                }
              })}
            </SortableContext>
            <DragOverlay>{activeItem && <DndLeftNavItem item={activeItem as TRoute} />}</DragOverlay>
          </DndContext>
        </div>
      </List>
    </>
  );
};

export default LeftNavMenu;
