import { FC, ReactNode } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const themes = createTheme({
    mixins: {
      toolbar: {
        minHeight: 46, //56
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
